import React from "react"
import {Col, Container, Row} from "react-bootstrap";
import {graphql} from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Agecolumns from "../../blocks/agecolumns";

export const data = graphql`query EirfaQuery {
  bird: file(relativePath: {eq: "SVG/home-bird.svg"}) {
    publicURL
  }
  fiveseven: file(relativePath: {eq: "cy/vocabulary/5-7_GemauGeirfa.pdf"}) {
    publicURL
  }
  sevennine: file(relativePath: {eq: "cy/vocabulary/7-9_GemauGeirfa.pdf"}) {
    publicURL
  }
  nineeleven: file(relativePath: {eq: "cy/vocabulary/9-11_GemauGeirfa.pdf"}) {
    publicURL
  }
}`

const VocabularyPage = ({ data }) => {
    const ageGroup = [
        {
            image: 'SVG/home-5-7.svg',
            ageGroup: '5-7',
            resourcesLink: data.fiveseven.publicURL,
            resourcesLinkText: 'Gweld ar-lein',
            glossaryLink: data.fiveseven.publicURL,
            glossaryLinkText: 'Lawrlwytho',
        },
        {
            image: 'SVG/home-7-9.svg',
            ageGroup: '7-9',
            resourcesLink: data.sevennine.publicURL,
            resourcesLinkText: 'Gweld ar-lein',
            glossaryLink: data.sevennine.publicURL,
            glossaryLinkText: 'Lawrlwytho',
        },
        {
            image: 'SVG/home-9-11.svg',
            ageGroup: '9-11',
            resourcesLink: data.nineeleven.publicURL,
            resourcesLinkText: 'Gweld ar-lein',
            glossaryLink: data.nineeleven.publicURL,
            glossaryLinkText: 'Lawrlwytho',
        }
    ]

    return (
        <Layout lang={'en'} langURL={'/en/vocabulary/'} navLang={'cy'}>
            <Seo title="Croeso i’r adnoddau geirf" description="All the information you need to teach your class" lang={'cy'}/>
            <section className={'home-banner'} role={'banner'}>
                <Container fluid={true} bsPrefix={'wrap'} className={'h-100'}>
                    <Row className={'h-100'}>
                        <div className={'home-banner--content offset-7 d-flex h-100'}>
                            <p className={'home-banner--content__text my-auto'}>
                                Syniadau ar gyfer gweithgaredd dysgu, adnoddau cymorth ac offer defnyddiol i athrawon!
                            </p>
                        </div>
                    </Row>
                </Container>
            </section>
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-0'}>
                            <h1 className={'content--h1'}>Croeso i’r adnoddau geirf</h1>
                            <p className={'mb-0'}>Mae’r adnoddau hyn wedi’u rhannu yn ôl ystod oedran i’ch helpu i benderfynu pa un i’w lawrlwytho ar gyfer y tasgau penodol sydd gennych mewn golwg.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'content--home--column--bird'}>
                            <p className={'pb-5'}>Mae fersiynau PDF ar gael i’w lawrlwytho a’u hargraffu ar gyfer eich dosbarth, yn ogystal â gweithgareddau a gemau rhyngweithiol i’w chwarae mewn parau a grwpiau bach.</p>
                            <img alt={'Little bird'} loading={'lazy'} src={data.bird.publicURL} style={{width: '60px'}}/>
                        </Col>
                        {ageGroup.map((val, idx) => (
                            <Agecolumns key={idx} image={val.image} ageGroup={val.ageGroup}
                                        resourcesLink={val.resourcesLink} resourcesLinkText={val.resourcesLinkText}
                                        glossaryLink={val.glossaryLink} glossaryLinkText={val.glossaryLinkText}/>
                        ))}
                    </Row>
                </Container>
            </main>
        </Layout>
    )

}

export default VocabularyPage